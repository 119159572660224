<template flex>
  <div class="dashboard">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container">
        <!-- Provides the application the proper gutter -->
        <div class="head-title light-bg d-flex justify-space-between mb-0 pa-9">
          <h2 class="headline font-weight-medium primary--text">
            {{ $t('pages.teamMgmt') }}
          </h2>
        </div>

        <v-container fluid class="px-9">
          <!-- If using vue-router -->
          <template fluid>
            <v-card elevation="0">
              <v-card-title class="px-0"></v-card-title>
              <v-card-text class="px-0">
                <v-card-title class="px-0">
                  {{ $t('teamMgmt.manageUsers') }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color=""
                        depressed
                        min-width="14"
                        max-width="14"
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="ml-4"
                      >
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('teamMgmt.invite') }}</span>
                  </v-tooltip>
                </v-card-title>

                <v-dialog v-model="addMemberDialog" max-width="600px">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="my-2">
                      <v-btn
                        color="success"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        class="ml-4"
                        ><v-icon>mdi-plus</v-icon>{{ $t('teamMgmt.add') }}</v-btn
                      >
                    </div>
                  </template>
                  <v-card class="pa-5">
                    <v-card-title
                      class="d-flex justify-space-between pa-0 mb-10"
                    >
                      <h4>{{ $t('teamMgmt.add') }}</h4>
                      <v-icon @click="closeAddMemberPopup()">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text class="pa-0">
                      <v-form class="mb-5" v-model="memberValid">
                        <v-row class="mt-0">
                          <v-col cols="12" sm="6" class="py-0">
                            <label class="black--text mb-3 d-flex"
                              >{{ $t('teamMgmt.first') }}</label
                            >
                            <v-text-field
                              placeholder="First Name"
                              v-model="memberDetails.FirstName"
                              required
                              outlined
                              dense
                              :rules="nameRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" class="py-0">
                            <label class="black--text mb-3 d-flex"
                              >{{ $t('teamMgmt.last') }}</label
                            >
                            <v-text-field
                              placeholder="Last Name"
                              v-model="memberDetails.LastName"
                              required
                              outlined
                              dense
                              :rules="lastNameRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col cols="12" sm="6" class="py-0">
                            <label class="black--text mb-3 d-flex">{{ $t('teamMgmt.phone') }}</label>
                            <v-text-field
                              placeholder="Enter Phone Number"
                              v-model="memberDetails.Phone"
                              required
                              outlined
                              dense
                              v-mask="'(###) ###-####'"
                              :rules="phoneRules"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" class="py-0">
                            <label class="black--text mb-3 d-flex">{{ $t('teamMgmt.email') }}</label>
                            <v-text-field
                              placeholder="Enter Email"
                              v-model="memberDetails.Email"
                              required
                              outlined
                              dense
                              :rules="emailRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col cols="12" sm="6" class="py-0">
                            <label class="black--text mb-3 d-flex"
                              >{{ $t('teamMgmt.job') }}</label
                            >
                            <v-text-field
                              placeholder="Job Title"
                              v-model="memberDetails.JobTitle"
                              required
                              outlined
                              dense
                              :rules="jobRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-3">
                          <v-col cols="12" sm="5" class="py-0">
                            <v-btn
                              color="success"
                              class="mr-3"
                              @click="addMember"
                              depressed
                              :disabled="!memberValid"
                              >{{ $t('teamMgmt.add') }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <!-- Add Member Popup End-->

                <div class="dataTable mt-8">
                  <h4>{{ $t('teamMgmt.allMembers') }}</h4>
                  <v-data-table
                    :headers="memberheaders"
                    :items="memberDetails"
                  >
                    <template v-slot:item.IsDisabled="{ item }">
                      <v-switch
                        v-if="item.InviteStatus === 'ACCEPTED'"
                        color="success"
                        v-model="!item.IsDisabled"
                        inset
                        outlined
                        @change="changeMemberStatus(item)"
                      ></v-switch>
                    </template>
                    <template v-slot:item.InviteStatus="{ item }">
                      {{ item.InviteStatus }}
                      <v-icon
                        v-if="item.InviteStatus === 'PENDING'"
                        title="Resend Invite"
                        small
                        class="mr-2"
                        @click="reInviteMember(item)"
                        color="orange"
                      >
                        mdi-email-send
                      </v-icon>
                    </template>
                    <template v-slot:item.CompanyName="{ item }">
                      {{ item.CompanyName }}
                    </template>
                  </v-data-table>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-container>
      </div>
      <div class="text-center">
        <v-dialog v-model="loading" hide-overlay persistent width="300">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ $t('teamMgmt.processing') }}
            </v-card-title>
            <v-card-text class="cust">
              <v-progress-linear
                indeterminate
                color="primary"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="text-center">
        <v-dialog v-model="msgModel" width="300">
          <v-card>
            <v-card-title class="headline grey lighten-2">
              {{ msgTitle }}
            </v-card-title>

            <v-card-text>
              {{ message }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeMsgModel(msgTitle)">
                {{ $t('teamMgmt.ok') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app>
  </div>
</template>

<script>
import Vue from 'vue';
import { LSS } from '@core/services';
import {
  ADD_MEMBER,
  CHANGE_MEMBER_STATUS,
  ReInvite_Member,
} from 'src/utils/apollo-mutations';
import { MEMBER_DETAILS } from 'src/utils/apollo-queries';
import VueMask from 'v-mask'
import { decodeToken } from '@shared/utils/commom.util';

Vue.use(VueMask)
export default Vue.extend({
  name: 'Settings',
  components: {},
  data() {
    return {
      message: '',
      msgTitle: 'info',
      memStatus: false,
      nameRules: [
        (v) => !!v || 'First Name is required',
        (v) =>
          (v && v.length < 13) ||
          'First Name must not be greater than 12 characters',
        (v) => !!/^[a-z]*$/gi.test(v) || 'Please enter valid first name!',
      ],
      lastNameRules: [
        (v) =>
          (v &&v.length) < 13 || 'Last Name must not be greater than 12 characters',
        (v) => /^[a-z]*$/gi.test(v) || 'Please enter valid name!',
      ],
      phoneRules: [
        (v) => !!v || 'Phone is required',
      ],
      companyRules: [(v) => !!v || 'Company Name is required'],
      jobRules: [(v) => !!v || 'Job Title is required'],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],

      email: '',
      password: '',
      checkbox: false,
      inviteDialog: false,
      select: '',
      emailForInvite: '',

      text: 'text',

      addMemberDialog: false,
      msgModel: false,
      loading: false,

      // memberDetails: {
      //   FirstName: '',
      //   LastName: '',
      //   Phone: '',
      //   Email: '',
      //   CompanyName: '',
      //   JobTitle: '',
      // },

      Role: null,
      memberDetails: [],

      emails: [
        {
          emailAddress: 'abc@gmail.com',
          updateData: 'Data',
          action: 'Delete',
        },
      ],

      headers2: [
        { text: 'Members', value: 'members' },
        { text: 'Role', value: 'role' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
      ],
      allMemberDetailsTable: [
        {
          members: 'Member 1',
          role: 'Data',
          status: 'Open',
          action: 'Delete',
        },
      ],

      isCheckboxTrue: false,

      onClickShow: false,
      memberValid: false,
    };
  },

  computed: {
    memberheaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('teamMgmt.first'), value: 'FirstName' },
          { text: this.$t('teamMgmt.last'), value: 'LastName' },
          { text: this.$t('teamMgmt.phone'), value: 'Phone' },
          { text: this.$t('teamMgmt.email'), value: 'Email' },
          { text: this.$t('teamMgmt.company'), value: 'CompanyName' },
          { text: this.$t('teamMgmt.job'), value: 'JobTitle' },
          { text: this.$t('teamMgmt.inviteStatus'), value: 'InviteStatus' },
          { text: this.$t('teamMgmt.isDisabled'), value: 'IsDisabled' },
        ]
      },
    },
  },
  methods: {
    allMemberDetails: async function () {
      this.loading = true;
      await this.$apollo
        .query({
          query: MEMBER_DETAILS,
          variables: {},
        })
        .then((res) => {
          //console.log(res.data.allMemberDetails)
          this.memberDetails = res.data.allMemberDetails
          this.loading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.loading = false;
        });
    },
    closeInvitePopup() {
      this.inviteDialog = false;
      this.emailForInvite = null;
    },
    addMember: async function () {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: ADD_MEMBER,
          variables: {
            memberDetails: {
              FirstName: this.memberDetails.FirstName,
              LastName: this.memberDetails.LastName,
              Phone: this.memberDetails.Phone,
              Email: this.memberDetails.Email,
              CompanyName: decodeToken(LSS.token).CompanyName,
              JobTitle: this.memberDetails.JobTitle,
            },
          },
        })
        .then((res) => {
          //console.log(res.data.addTeamMember.successMessage);
          if (res.data.addTeamMember.successMessage == 'Exist') {
            this.loading = false;
            this.closeAddMemberPopup();
            this.msgModel = true;
            this.msgTitle = 'Error!!!';
            this.message = 'Member with this Email ID already Exist.';
          } else {
            this.loading = false;
            this.closeAddMemberPopup();
            this.msgModel = true;
            this.msgTitle = 'Success';
            this.message = 'Member Added.';
          }
        })
        .catch((error) => {
          //console.log(error);
          this.loading = false;
          this.closeAddMemberPopup();
          this.msgModel = true;
          this.msgTitle = 'Error!!!';
          this.message = 'Something Went Wrong.';
        });
    },
    closeAddMemberPopup() {
      this.addMemberDialog = false;
      this.memberDetails = {
        FirstName: null,
        LastName: null,
        Phone: null,
        Email: null,
        CompanyName: null,
        JobTitle: null,
      };
    },
    reInviteMember: async function (data) {
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: ReInvite_Member,
          variables: {
            teamInviteReq: {
              Email: data.Email,
            },
          },
        })
        .then((res) => {
          this.loading = false;
          this.msgModel = true;
          this.msgTitle = 'Success!';
          this.message = 'Invite Link Sent';
        })
        .catch((error) => {
          //console.log(error);
          this.loading = false;
          this.msgModel = true;
          this.msgTitle = 'Error!!!';
          this.message = 'Something Went Wrong.';
        });
    },
    changeMemberStatus: async function (data) {
      try {
        await this.$apollo.mutate({
          mutation: CHANGE_MEMBER_STATUS,
          variables: {
            changeMemberStatusReq: {
              Email: data.Email,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    closeMsgModel(msgTitle) {
      if (msgTitle == 'Success') {
        window.location.reload();
      } else {
        this.msgModel = false;
      }
    },
  },
  mounted() {
    this.allMemberDetails();
  },
});
</script>
