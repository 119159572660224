var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c(
              "div",
              {
                staticClass:
                  "head-title light-bg d-flex justify-space-between mb-0 pa-9"
              },
              [
                _c(
                  "h2",
                  { staticClass: "headline font-weight-medium primary--text" },
                  [_vm._v(" " + _vm._s(_vm.$t("pages.teamMgmt")) + " ")]
                )
              ]
            ),
            _c(
              "v-container",
              { staticClass: "px-9", attrs: { fluid: "" } },
              [
                [
                  _c(
                    "v-card",
                    { attrs: { elevation: "0" } },
                    [
                      _c("v-card-title", { staticClass: "px-0" }),
                      _c(
                        "v-card-text",
                        { staticClass: "px-0" },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "px-0" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("teamMgmt.manageUsers")) +
                                  " "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "ml-4",
                                                  attrs: {
                                                    color: "",
                                                    depressed: "",
                                                    "min-width": "14",
                                                    "max-width": "14",
                                                    text: ""
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  "mdi-information-outline"
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("teamMgmt.invite")))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "600px" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "my-2" },
                                        [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "ml-4",
                                                  attrs: {
                                                    color: "success",
                                                    depressed: ""
                                                  }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus")
                                              ]),
                                              _vm._v(
                                                _vm._s(_vm.$t("teamMgmt.add"))
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.addMemberDialog,
                                callback: function($$v) {
                                  _vm.addMemberDialog = $$v
                                },
                                expression: "addMemberDialog"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "pa-5" },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between pa-0 mb-10"
                                    },
                                    [
                                      _c("h4", [
                                        _vm._v(_vm._s(_vm.$t("teamMgmt.add")))
                                      ]),
                                      _c(
                                        "v-icon",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.closeAddMemberPopup()
                                            }
                                          }
                                        },
                                        [_vm._v("mdi-close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-form",
                                        {
                                          staticClass: "mb-5",
                                          model: {
                                            value: _vm.memberValid,
                                            callback: function($$v) {
                                              _vm.memberValid = $$v
                                            },
                                            expression: "memberValid"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "teamMgmt.first"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      placeholder: "First Name",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      rules: _vm.nameRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.memberDetails
                                                          .FirstName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.memberDetails,
                                                          "FirstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "memberDetails.FirstName"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "teamMgmt.last"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      placeholder: "Last Name",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      rules: _vm.lastNameRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.memberDetails
                                                          .LastName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.memberDetails,
                                                          "LastName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "memberDetails.LastName"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "teamMgmt.phone"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: "(###) ###-####",
                                                        expression:
                                                          "'(###) ###-####'"
                                                      }
                                                    ],
                                                    attrs: {
                                                      placeholder:
                                                        "Enter Phone Number",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      rules: _vm.phoneRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.memberDetails.Phone,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.memberDetails,
                                                          "Phone",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "memberDetails.Phone"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "teamMgmt.email"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      placeholder:
                                                        "Enter Email",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      rules: _vm.emailRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.memberDetails.Email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.memberDetails,
                                                          "Email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "memberDetails.Email"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12", sm: "6" }
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "black--text mb-3 d-flex"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("teamMgmt.job")
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      placeholder: "Job Title",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      rules: _vm.jobRules
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.memberDetails
                                                          .JobTitle,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.memberDetails,
                                                          "JobTitle",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "memberDetails.JobTitle"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "py-0",
                                                  attrs: { cols: "12", sm: "5" }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        color: "success",
                                                        depressed: "",
                                                        disabled: !_vm.memberValid
                                                      },
                                                      on: {
                                                        click: _vm.addMember
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("teamMgmt.add")
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "dataTable mt-8" },
                            [
                              _c("h4", [
                                _vm._v(_vm._s(_vm.$t("teamMgmt.allMembers")))
                              ]),
                              _c("v-data-table", {
                                attrs: {
                                  headers: _vm.memberheaders,
                                  items: _vm.memberDetails
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.IsDisabled",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        item.InviteStatus === "ACCEPTED"
                                          ? _c("v-switch", {
                                              attrs: {
                                                color: "success",
                                                inset: "",
                                                outlined: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.changeMemberStatus(
                                                    item
                                                  )
                                                }
                                              },
                                              model: {
                                                value: !item.IsDisabled,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    !item,
                                                    "IsDisabled",
                                                    $$v
                                                  )
                                                },
                                                expression: "!item.IsDisabled"
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.InviteStatus",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " + _vm._s(item.InviteStatus) + " "
                                        ),
                                        item.InviteStatus === "PENDING"
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  title: "Resend Invite",
                                                  small: "",
                                                  color: "orange"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.reInviteMember(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" mdi-email-send ")]
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.CompanyName",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " + _vm._s(item.CompanyName) + " "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "v-dialog",
              {
                attrs: { "hide-overlay": "", persistent: "", width: "300" },
                model: {
                  value: _vm.loading,
                  callback: function($$v) {
                    _vm.loading = $$v
                  },
                  expression: "loading"
                }
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "headline grey lighten-2" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("teamMgmt.processing")) + " "
                        )
                      ]
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "cust" },
                      [
                        _c("v-progress-linear", {
                          staticClass: "mb-0",
                          attrs: { indeterminate: "", color: "primary" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "v-dialog",
              {
                attrs: { width: "300" },
                model: {
                  value: _vm.msgModel,
                  callback: function($$v) {
                    _vm.msgModel = $$v
                  },
                  expression: "msgModel"
                }
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "headline grey lighten-2" },
                      [_vm._v(" " + _vm._s(_vm.msgTitle) + " ")]
                    ),
                    _c("v-card-text", [
                      _vm._v(" " + _vm._s(_vm.message) + " ")
                    ]),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function($event) {
                                return _vm.closeMsgModel(_vm.msgTitle)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("teamMgmt.ok")) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }